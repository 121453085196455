.App {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
}

.App-header {
  background-color: #262626;
  overflow: visible;
  width: 100%;
  text-align: center;
  color: white;
}

.Messages-list {
  margin: 30px 0;
  width: 100%;
  max-width: 90vw;
  align-self: center;
  margin: 0 auto;
  list-style: none;
  padding-left: 0;
  flex-grow: 1;
  overflow: auto;
}

.Messages-message {
  display: flex;
  margin-top: 10px;
}

.Messages-message.currentMember {
  /*justify-content: flex-end;*/
  flex-direction: row-reverse;
  text-align: right;
}

.Message-content {
  display: inline-block;
}

.currentMember > .Message-content {
  align-items: flex-end;
}

.Messages-message > .avatar {
  height: 35px;
  width: 35px;
  border-radius: 50%;
  display: inline-block;
  margin: 0 10px -10px;
}

.Message-content > .username {
  display: block;
  color: gray;
  font-size: 14px;
  padding-bottom: 4px;
}

.Message-content > .text {
  padding: 10px;
  max-width: 400px;
  margin: 0;
  border-radius: 12px;
  background-color: cornflowerblue;
  color: white;
  display: inline-block;
}

.currentMember > .Message-content .text {
  background-color: orangered;
}

form {
  display: flex;
  width: 100%;
  justify-content: space-between;
  max-width: 900px;
  margin: 0 auto 40px;
}

input {
  padding: 5px;
  font-size: 16px;
  border-radius: 8px;
  border: 1px solid orangered;
  flex-grow: 1;
}

button {
  padding: 5px 10px;
  font-size: 16px;
  background-color: orangered;
  color: white;
  border: none;
  border-radius: 8px;
  margin-left: 10px;
}

.markerHolder {
  height: 50px;
  width: 60px;
  float: right;
}

.marker {
  top: 10px;
  font-size: 60px !important;
  position: absolute;
}

.markerText {
  top: 0;
  right: 0;
  position: absolute;
  font-size: 20px;
}

.event {
  position: absolute;
  bottom: 0;
  width: 100%;
  min-height: 15vh;
  background-color: #262626;
  text-align: center;
  transform: translateY(100%);
  transition: all 0.4s;
  color: white;
}
.event svg {
  padding: 10px;
  fill: white;
  font-size: 50px !important;
}

.event_active {
  position: absolute;
  bottom: 0;
  width: 100%;
  min-height: 15vh;
  background-color: #262626;
  text-align: center;
  transform: translateY(0%);
  transition: all 0.2s;
  color: white;
}
.event_active svg {
  padding: 10px;
  fill: white;
  font-size: 50px !important;
}

.event--top {
  position: absolute;
  top: 0;
  width: 100%;
  min-height: 10vh;
  background-color: #262626;
  text-align: center;
  transform: translateY(-100%);
  transition: all 0.4s;
  z-index: 1101;
  color: white;
}

.event--top_active {
  position: absolute;
  top: 0;
  width: 100%;
  min-height: 10vh;
  background-color: #262626;
  text-align: center;
  transform: translateY(0%);
  transition: all 0.2s;
  z-index: 1101;
  color: white;
}

.header {
  transform: translateY(-100%);
  transition: all 0.2s;
}
.header_active {
  transform: translateY(0%);
  transition: all 0.2s;
}

.Input, .Messages-list {
  max-width: 90vw;
  align-self: center;
  width: 100%;
}
.Input {
  padding-top: 30px;
}





.member-list-div {
  background-color: white;
  position: absolute;
  width: 80vw;
  height: 80vh;
  max-height: 80vh;
  max-width: 500px;
  top: 100%;
  left: 50%;
  transform: translate(-50%, 0);
  display: flex;
  flex-direction: column;
  overflow: auto;
  transition: all 0.5s;
}
.member-list-div_active {
  background-color: white;
  position: absolute;
  width: 80vw;
  height: 80vh;
  max-height: 80vh;
  max-width: 500px;
  top: 50%;
  left: 50%;
  display: flex;
  flex-direction: column;
  transform: translate(-50%, -50%);
  overflow: auto;
  transition: all 0.5s;
}
.chat-member {
  list-style-type: none;
  padding-top: 15px;
  padding-bottom: 15px;
}
.chat-member img {
  max-height: 50px;
  max-width: 50px;
  float: left;
  margin-right: 20px;
}
.member-desc-holder {
  display: flex;
  float: left;
  flex-direction: column;
  justify-content: center;
}
.member-name {
  margin-bottom: 0;
  margin-top: 0;
}

.member-online {
  margin-top: 5px;
  margin-bottom: 0;
}

.member-message {
  float: right;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cross {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}

.trigger {
  cursor: pointer;
}
